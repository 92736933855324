import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useBreadCrumbs } from '../hooks/useBreadcrumbs'
import { Typography, Box } from '@material-ui/core'

export default props => {
  const { title, slug, image, body, caption } = props.data.contentfulProducts
  const breadCrumbs = useBreadCrumbs(slug, 'products', title)

  return (
    <Layout breadCrumbs={breadCrumbs} title={`Продукция: ${title}`}>
      <SEO title={`Продукция: ${title}`} />
      <Box mb={3}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Typography variant="subtitle1">{caption}</Typography>
      <img
        src={image.file.url}
        height="400px"
        title={image.title}
        alt={image.title}
      />

      <div>{documentToReactComponents(body.json)}</div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulProducts(slug: { eq: $slug }) {
      title
      slug
      caption
      image {
        title
        file {
          url
        }
      }
      body {
        json
      }
    }
  }
`
